import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

const config = {
  headers: {
    "Content-Type": "application/json", // Example header, you can add more headers as needed
    // Authorization: `${token}`, // Example Authorization header
  },
};
const getEventMasterList = async () => {
  try {
    const result = await axios
      .get(`${URL}/event/get-event-list`, {
        headers: {
          //Authorization: `${token}`, // Include token in Authorization header
          "Content-Type": "application/json", // Set content type if needed
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

const getEventMasterDetails = async (url) => {
  try {
    const result = await axios
      .get(`${URL}/event/get-event-by-title`, {
        params: { url }, // Pass the title as a query parameter
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

const submitRegisteredEventUser = async (data) => {
  try {
    const result = await axios
      .post(`${URL}/user/create-user`, data, config)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};
const testimonialMasterGetList = async () => {
  try {
    const result = await axios
      .get(`${URL}/testimonials/get-testimonials-list`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};

export {
  submitRegisteredEventUser,
  getEventMasterList,
  getEventMasterDetails,
  testimonialMasterGetList,
};
