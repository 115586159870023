import toast from "react-hot-toast";

const Toast = (myPromise) => {
  return toast.promise(myPromise, {
    loading: "Loading...",
    success: (response) => {
      return response.data.message || "Success"; // Use message from API or default
    },
    error: (error) => {
      if (error.response) {
        return error.response.data.message || "An error occurred"; // API error message
      } else if (error.request) {
        return "An error occurred while making the request"; // Request error
      } else {
        return "An unexpected error occurred"; // General error
      }
    },
  });
};

export default Toast;
