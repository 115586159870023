import { useRoutes } from "react-router-dom";
import Routers from "./router/router";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./util/ScrollToTop";
function App() {
  const routes = useRoutes(Routers);
  return (
    <ScrollToTop>
      <div>
        <Toaster />
        {routes}
      </div>
    </ScrollToTop>
  );
}

export default App;
