import React, { useEffect, useState } from "react";
import shape05 from "../assets/img/shape/05.png";
import iconEvent from "../assets/img/icon/correct.png";
import location from "../assets/img/icon/correct.png";
import iconIdea from "../assets/img/icon/correct.png";
import iconquote from "../assets/img/icon/quote.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  getEventMasterDetails,
  getEventMasterList,
  testimonialMasterGetList,
} from "../service/EventMasterService/EventMasterService";
import { homeDetailsList } from "../service/HomeMaster/HomeMaster";
import workshopIcon from "../assets/img/icon/correct.png";


const Home = () => {
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_API_URL;
  const baseURL = URL.replace("/v1", "");
  const imageURL = `${baseURL}/images/`;

  const [eventList, setEvenList] = useState([]);
  const [homeValue, setHomeValue] = useState({});
  const [testimonialList, setTestimonialList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedVideo, setSelectedVideo] = useState("");

  const fetchEventsList = () => {
    getEventMasterList()
      .then((res) => {
        const filteredEvents = res.data.data.filter(
          (event) => event.status === "1"
        );
        const limitedEvents = filteredEvents.slice(0, 3); // Limiting to first 3 events
        setEvenList(limitedEvents);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchTestimonialList = () => {
    testimonialMasterGetList()
      .then((res) => {
        const values = res?.data?.data;
        setTestimonialList(values);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEventsList();
    fetchTestimonialList();
  }, []);

  // const handleEventDetails = (event) => {
  //   navigate(`/event/${event.url}`, { state: { event } });
  // };

  const handleEventDetails = (event) => {
    getEventMasterDetails(event?.url)
      .then((res) => {
        const response = res?.data?.data;
        navigate(`/event/${response.url}`, { state: { response } });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const getHomeDetails = () => {
    homeDetailsList()
      .then((res) => {
        const data = res?.data?.data[0];
        setHomeValue(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getHomeDetails();
  }, []);

  useEffect(() => {
    if (
      !loading &&
      window.jQuery &&
      window.jQuery(".owl-carousel").owlCarousel
    ) {
      window.jQuery(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        items: 3,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }
  }, [loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return "";

    // Extract video ID from standard, shortened, or share URLs
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/]+)/
    );
    if (!videoIdMatch) return "";

    const videoId = videoIdMatch[1];

    return `https://www.youtube.com/embed/${videoId}`;
  };
  const handleCloseVideo = () => {
    setSelectedVideo("");
  };

  return (
    <div className="main">
      <div className="hero-section">
        <div
          className="hero-single"
          style={{
            background: homeValue?.bannerImage
              ? `url(${imageURL}${homeValue?.bannerImage})`
              : "none", // Fallback background
          }}
        >
          <div className="hero-shape">
            <img src={shape05} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-content">
                  {/* <div className="hero-date">
                    <h1>25</h1>
                    <div className="date-content">
                      <span>May 2024</span>
                      <p>25/B Milford Road, New York, USA</p>
                    </div>
                  </div> */}
                  {/* <h1 className="hero-title">
                    Digital World <span>Conference</span>
                  </h1> */}
                  <ul className="hero-points">
                    <li>
                      Exclusive and Focused <span>Gatherings</span>
                      <br />
                      <br />
                    </li>
                    <li>
                      Meaningful Collaboration for <span>Problem-Solving</span>
                      <br />
                      <br />
                    </li>
                    <li>
                      Community Building and <span>Ongoing Support</span>
                    </li>
                  </ul>
                  <div className="hero-btn">
                    <Link to="/contact" className="theme-btn">
                      Contact<i className="fas fa-arrow-right"></i>
                    </Link>
                    <Link to="/about" className="theme-btn theme-btn2">
                      Learn More<i className="fas fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img-wrap">
                  <div className="hero-img">
                    <img
                      alt=""
                      src={`${imageURL}${homeValue?.featuredImage}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-area py-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left wow fadeInRight" data-wow-delay=".25s">
                <div className="about-img">
                  <img
                    className="img-1"
                    alt=""
                    src={`${imageURL}${homeValue?.featuredAboutImage}`}
                  />
                  <img
                    className="img-2"
                    src={`${imageURL}${homeValue?.featuredAboutSecImage}`}
                    alt=""
                  />
                  <img
                    className="img-3"
                    src={`${imageURL}${homeValue?.featuredAboutThirdImage}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInLeft" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">
                    {" "}
                    {homeValue?.aboutTitle}
                  </span>
                  <div
                    className="site-shadow-text wow fadeInRight"
                    data-wow-delay=".35s"
                  >
                    {homeValue?.aboutTitle}
                  </div>
                </div>
                <p
                  className="about-text"
                  dangerouslySetInnerHTML={{
                    __html: homeValue?.aboutDescription
                      ? JSON.parse(homeValue.aboutDescription)
                      : "",
                  }}
                ></p>

                <div className="about-list-wrap">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point1}</span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point2}</span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point3}</span>
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
                {/*<a href="about.html" className="theme-btn">
                  Discover More<i className="fas fa-arrow-right"></i>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="counter-area pt-80 pb-80"
        style={{
          background: homeValue?.funfactImage
            ? `url(${imageURL}${homeValue?.funfactImage})`
            : "none", // Fallback background
        }}
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-5">
              <div className="counter-info">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-white">
                    SuiteLink{" "}
                  </span>
                  <h2 className="site-title text-white">
                    {homeValue?.funfactTitle}
                  </h2>
                </div>
                <p className="text-white">{homeValue?.funfactContent}</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row g-4 justify-content-center">
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={workshopIcon} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          // data-count="+"
                          // data-to="150"
                          data-speed="3000"
                        >
                          {homeValue?.funfactTitle1}
                        </span>
                        {/* <span className="counter-unit">+</span>*/}
                      </div>
                      <h6 className="title"> {homeValue?.funfact1}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInDown"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={workshopIcon} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="260"
                          data-speed="3000"
                        >
                          {homeValue?.funfactTitle2}
                        </span>
                        {/*<span className="counter-unit">K</span>*/}{" "}
                      </div>
                      <h6 className="title">{homeValue?.funfact2}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={workshopIcon} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="120"
                          data-speed="3000"
                        >
                          {homeValue?.funfactTitle3}
                        </span>
                      </div>
                      <h6 className="title">{homeValue?.funfact3}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInDown"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={workshopIcon} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="50"
                          data-speed="3000"
                        >
                          {homeValue?.funfactTitle4}
                        </span>
                      </div>
                      <h6 className="title">{homeValue?.funfact4}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {eventList.length > 0 && (
        <div className="venue-area py-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="site-heading text-center wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <span className="site-title-tagline">Events</span>
                  <h2 className="site-title">
                    Explore Our <span>Events</span>
                  </h2>
                  <div className="site-shadow-text">Events</div>
                </div>
              </div>
            </div>
            <div className="row g-5 wow fadeInUp" data-wow-delay=".25s">
              {eventList.map((item, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <div
                    className="venue-item"
                    onClick={() => handleEventDetails(item)}
                  >
                    <div className="venue-img">
                      <img src={`${imageURL}${item?.image[0]}`} alt="images" />
                    </div>
                    <div className="venue-content">
                      <span>{item?.title}</span>
                      {item?.link && (
                        <div class="venue-play">
                          <a
                            class="popup-youtube"
                            onClick={() => handleVideoClick(item?.link)}
                          >
                            <span>Video</span>
                            <i class="fas fa-play"></i>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="text-center mt-40 wow fadeInUp"
              data-wow-delay=".25s"
            >
              <a href="#/events" className="theme-btn" target="_blank">
                <span></span> View All
              </a>
            </div>
          </div>
        </div>
      )}

      {selectedVideo && (
        <div className="video-overlay">
          <div className="video-container">
            <span className="close" onClick={handleCloseVideo}>
              &times;
            </span>
            <iframe
              width="100%"
              height="315"
              src={getYouTubeEmbedUrl(selectedVideo)}
              frameBorder="0"
              allowFullScreen
              title="Video"
            />
          </div>
        </div>
      )}

      <div className="choose-area py-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="choose-img wow fadeInLeft" data-wow-delay=".25s">
                <img
                  alt=""
                  src={`${imageURL}${homeValue?.featuredWhyUsImage}`}
                />
                <Link to="/about" className="theme-btn">
                  Learn More <i className="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="choose-content wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div className="site-heading mb-0">
                  <h2 className="site-title mb-10">{homeValue?.whyUsTitle}</h2>
                </div>
                <div className="choose-content-wrap">
                  <div className="choose-item">
                    <div className="choose-item-icon">
                      <img src={iconEvent} alt="" />
                    </div>
                    <div className="choose-item-info">
                      <h4>{homeValue?.whyUsSubTitle1}</h4>
                      <p>{homeValue?.whyUsSubText1}</p>
                    </div>
                  </div>
                  <div className="choose-item">
                    <div className="choose-item-icon">
                      <img src={location} alt="" />
                    </div>
                    <div className="choose-item-info">
                      <h4>{homeValue?.whyUsSubTitle2}</h4>
                      <p>{homeValue?.whyUsSubText2}</p>
                    </div>
                  </div>
                  <div className="choose-item">
                    <div className="choose-item-icon">
                      <img src={iconIdea} alt="" />
                    </div>
                    <div className="choose-item-info">
                      <h4>{homeValue?.whyUsSubTitle3}</h4>
                      <p>{homeValue?.whyUsSubText3}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {testimonialList?.length !== 0 && (
        <div className="testimonial-area  bg py-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="site-heading text-center wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <span className="site-title-tagline">Testimonials</span>
                  <h2 className="site-title">
                    What Our <span>Guest Say's</span> <br /> About Us
                  </h2>
                  <div className="site-shadow-text">Testimonials</div>
                </div>
              </div>
            </div>

            <div
              className="testimonial-slider owl-carousel owl-theme wow fadeInUp"
              data-wow-delay=".25s"
            >
              {testimonialList?.map((testimonial, index) => (
                <div className="testimonial-single" key={index}>
                  <div className="testimonial-quote">
                    <p>{testimonial?.feedback}</p>
                    <div className="testimonial-quote-icon">
                      <img src={iconquote} alt="" />
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      <img
                        alt=""
                        src={`${imageURL}${testimonial?.testimonialImage}`}
                      />
                    </div>
                    <div className="testimonial-author-info">
                      <h4>{testimonial?.name}</h4>
                      <p>{testimonial?.designation}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
