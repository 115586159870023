import React from "react";

const sideBar = () => {
  return (
    <div className="sidebar-popup">
      <div className="sidebar-wrapper">
        <div className="sidebar-content">
          <button type="button" className="close-sidebar-popup">
            <i className="far fa-xmark" />
          </button>
          <div className="sidebar-logo">
            <img src="../assets/img/logo/logo.png" alt="image" />
          </div>
          <div className="sidebar-about">
            <h4>About Us</h4>
            <p>
              There are many variations of passages available sure there
              majority have suffered alteration in some form by inject humour or
              randomised words which don't look even slightly believable.
            </p>
          </div>
          <div className="sidebar-contact">
            <h4>Contact Info</h4>
            <ul>
              <li>
                <h6>Email</h6>
                <a href="mailto:info@example.com">
                  <i className="far fa-envelope" />
                  info@example.com
                </a>
              </li>
              <li>
                <h6>Phone</h6>
                <a href="tel:+21236547898">
                  <i className="far fa-phone" />
                  +2 123 654 7898
                </a>
              </li>
              <li>
                <h6>Address</h6>
                <a href="#">
                  <i className="far fa-location-dot" />
                  25/B Milford Road, New York
                </a>
              </li>
            </ul>
          </div>
          <div className="sidebar-social">
            <h4>Follow Us</h4>
            <a href="#">
              <i className="fab fa-facebook" />
            </a>
            <a href="#">
              <i className="fab fa-x-twitter" />
            </a>
            <a href="#">
              <i className="fab fa-instagram" />
            </a>
            <a href="#">
              <i className="fab fa-linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default sideBar;
