import React from "react";

const Tooltip = ({ content, children }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip">{content}</div>
    </div>
  );
};

export default Tooltip;
