import React from "react";
import PublicLayout from "../layout/publicLayout";
import Home from "../component/Home";
import About from "../component/About";
import Contact from "../component/Contact";
import Event from "../component/Event";
import EventDetails from "../component/EventDetails";
import Lunch from "../component/Lunch";
import Dinner from "../component/Dinner";
import Sports from "../component/Sports";

const Routers = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/services/lunch",
        element: <Lunch />,
      },
      {
        path: "/services/dinner",
        element: <Dinner />,
      },
      {
        path: "/services/sports",
        element: <Sports />,
      },
      {
        path: "/events",
        element: <Event />,
      },
      {
        path: "/event/:eventUrl",
        element: <EventDetails />,
      },
    ],
  },
];

export default Routers;
