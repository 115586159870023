import React from "react";
import Footer from "./component/footer";
import Header from "./component/header";
// import Loading from "./component/loading";
import Scrollup from "./component/scrollup";
import Search from "./component/search";
import SideBar from "./component/sideBar";
import { Outlet, useLocation } from "react-router-dom";
const PublicLayout = () => {
  const location = useLocation();

  const isEventDetailPage = /^\/event\/[^/]+$/.test(location?.pathname);

  return (
    <div className="home-2">
      {!isEventDetailPage && <Header />}
      {!isEventDetailPage && <Search />}
      {!isEventDetailPage && <SideBar />}
      <Outlet />
      {!isEventDetailPage && <Footer />}
      <Scrollup />
    </div>
  );
};

export default PublicLayout;
