import React, { useEffect, useState } from "react";
import breadcrumb01 from "../assets/img/about/mainimage.jpg";
import { homeDetailsList } from "../service/HomeMaster/HomeMaster";
import { testimonialMasterGetList } from "../service/EventMasterService/EventMasterService";
import iconquote from "../assets/img/icon/quote.svg";

const URL = process.env.REACT_APP_API_URL;
const baseURL = URL.replace("/v1", "");
const imageURL = `${baseURL}/images/`;

const About = () => {
  const [homeValue, setHomeValue] = useState({});
  const [testimonialList, setTestimonialList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getHomeDetails = () => {
    homeDetailsList()
      .then((res) => {
        const data = res?.data?.data[0];
        setHomeValue(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getHomeDetails();
  }, []);

  const fetchTestimonialList = () => {
    testimonialMasterGetList()
      .then((res) => {
        const values = res?.data?.data;
        setTestimonialList(values);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTestimonialList();
  }, []);

  useEffect(() => {
    if (
      !loading &&
      window.jQuery &&
      window.jQuery(".owl-carousel").owlCarousel
    ) {
      window.jQuery(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        items: 3,
      });
    }
  }, [loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main">
      <div
        className="site-breadcrumb"
        style={{
          background: homeValue?.aboutbannerImage
            ? `url(${imageURL}${homeValue?.aboutbannerImage})`
            : "none", // Fallback background
        }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">About</h2>
          <ul className="breadcrumb-menu">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li className="active">About</li>
          </ul>
        </div>
      </div>

      <div className="about-area py-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left wow fadeInRight" data-wow-delay=".25s">
                <div className="about-img">
                  <img
                    className="img-1"
                    alt=""
                    src={`${imageURL}${homeValue?.featuredAboutImage}`}
                  />
                  <img
                    className="img-2"
                    src={`${imageURL}${homeValue?.featuredAboutSecImage}`}
                    alt=""
                  />
                  <img
                    className="img-3"
                    src={`${imageURL}${homeValue?.featuredAboutThirdImage}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right wow fadeInLeft" data-wow-delay=".25s">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">
                    {homeValue?.aboutTitle}
                  </span>
                </div>

                <p
                  className="about-text"
                  dangerouslySetInnerHTML={{
                    __html: homeValue?.aboutDescription
                      ? JSON.parse(homeValue.aboutDescription)
                      : "",
                  }}
                ></p>
                <div className="about-list-wrap">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point1}</span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point2}</span>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="about-item">
                        <h5>
                          <span>{homeValue?.point3}</span>
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
                {/*<a href="about.html" className="theme-btn">
                  Discover More<i className="fas fa-arrow-right"></i>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="counter-area pt-80 pb-80"
        style={{ background: `url(${shape02})`,marginBottom:"70px" }}
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-5">
              <div className="counter-info">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline text-white">
                    Fun Fact
                  </span>
                  <h2 className="site-title text-white">
                    We inspire people to be creative enough.
                  </h2>
                </div>
                <p className="text-white">
                  There are many variations of passages the majority have
                  suffered alteration in some form slightly believable. If you
                  are going to use a passage of need to be sure.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row g-4 justify-content-center">
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={iconworkshop} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="150"
                          data-speed="3000"
                        >
                          150
                        </span>
                        <span className="counter-unit">+</span>
                      </div>
                      <h6 className="title">Unique Workshops</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInDown"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={iconparticipant} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="260"
                          data-speed="3000"
                        >
                          260
                        </span>
                        <span className="counter-unit">K</span>
                      </div>
                      <h6 className="title">Event Participants</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInUp"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={iconspeaker} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="120"
                          data-speed="3000"
                        >
                          120
                        </span>
                        <span className="counter-unit">+</span>
                      </div>
                      <h6 className="title">Skilled Speakers</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="counter-box wow fadeInDown"
                    data-wow-delay=".25s"
                  >
                    <div className="icon">
                      <img src={iconaward} alt="" />
                    </div>
                    <div className="counter-content">
                      <div className="counter-info">
                        <span
                          className="counter"
                          data-count="+"
                          data-to="50"
                          data-speed="3000"
                        >
                          50
                        </span>
                        <span className="counter-unit">+</span>
                      </div>
                      <h6 className="title">Win Awards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {testimonialList?.length !== 0 && (
        <div className="testimonial-area bg py-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="site-heading text-center wow fadeInDown"
                  data-wow-delay=".25s"
                >
                  <span className="site-title-tagline">Testimonials</span>
                  <h2 className="site-title">
                    What Our <span>Guest Say's</span> <br /> About
                  </h2>
                  <div className="site-shadow-text">Testimonials</div>
                </div>
              </div>
            </div>
            <div
              className="testimonial-slider owl-carousel owl-theme wow fadeInUp"
              data-wow-delay=".25s"
            >
              {testimonialList.map((testimonial, index) => (
                <div className="testimonial-single" key={index}>
                  <div className="testimonial-quote">
                    <p>{testimonial.feedback}</p>
                    <div className="testimonial-quote-icon">
                      <img src={iconquote} alt="" />
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      <img
                        alt=""
                        src={`${imageURL}${testimonial.testimonialImage}`}
                      />
                    </div>
                    <div className="testimonial-author-info">
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.designation}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/*  <div className="team-area py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="site-heading text-center wow fadeInDown"
                data-wow-delay=".25s"
              >
                <span className="site-title-tagline">Speakers</span>
                <h2 className="site-title">
                  Meet Creative <span>Speakers</span>
                </h2>
                <div className="site-shadow-text">Speakers</div>
              </div>
            </div>
          </div>
          <div className="row g-4 wow fadeInUp" data-wow-delay=".25s">
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker01} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Ronel Smith</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker02} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Malissa Fie</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker03} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Bodney Rios</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker04} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Durton Mary</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker05} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Heidi Powers</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <div className="team-item">
                <div className="team-img">
                  <img src={speaker06} alt="" />
                </div>
                <div className="team-content">
                  <div className="social">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Miller Carla</a>
                    </h4>
                    <span>UX Deginer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-area partner-bg py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="site-heading text-center wow fadeInDown"
                data-wow-delay=".25s"
              >
                <span className="site-title-tagline">Sponsors</span>
                <h2 className="site-title">
                  Let's Check Our <span>Sponsors</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="partner-wrapper wow fadeInUp" data-wow-delay=".25s">
            <div className="row g-5 justify-content-center">
              <div className="col-6 col-md-2">
                <img src={partner01} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner02} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner03} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner04} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner05} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner06} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner07} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner08} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner09} alt="thumb" />
              </div>
              <div className="col-6 col-md-2">
                <img src={partner10} alt="thumb" />
              </div>
            </div>
          </div>
          <div className="text-center mt-40 wow fadeInUp" data-wow-delay=".25s">
            <a href="#" className="theme-btn">
              <span className="fal fa-users"></span> Become Sponsors
            </a>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default About;
