import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

// const token = Cookies.get("token");

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const contactDetailsList = async () => {
  try {
    const result = await axios
      .get(`${URL}/contactus/get-contactUs-list`, {
        headers: {
          // Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};
const submitContactDetails = async (data) => {
  try {
    const result = await axios
      .post(`${URL}/contactus/contactUs-create`, data, config)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    return error;
  }
};
export { contactDetailsList, submitContactDetails };
