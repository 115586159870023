import React from "react";

const search = () => {
  return (
    <div className="search-popup">
      <button className="close-search">
        <span className="far fa-times" />
      </button>
      <form action="#">
        <div className="form-group">
          <input
            type="search"
            name="search-field"
            placeholder="Search Here..."
            required
          />
          <button type="submit">
            <i className="far fa-search" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default search;
